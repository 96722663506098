import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ErrorMessage } from '../../domain/error/error-message.model';
import { decodeError } from '../../domain/error/error.util';
import { getOneAndWaitforLoaded } from '../../shared/util/operators';
import { selectAuthLoginState } from '../auth/auth.selectors';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { LogService } from '../log/log.service';
import { createHardLoginSession, createHardLoginSessionFailure, createHardLoginSessionSuccess, createSession, createSessionFailure, createSessionSuccess } from './session.actions';
import { SessionService } from './session.service';
import { TranslateService } from '@ngx-translate/core';
import { ClickLog, ClickOperation } from '../clicks/click.models';
import { saveClickLog } from '../clicks/click.actions';

@Injectable({ providedIn: 'root' })
export class SessionEffects {
	createSession$ = createEffect(() =>
		this.actions$.pipe(
			ofType(createSession),
			getOneAndWaitforLoaded(this.store),
			tap((action) => this.logService.info('Effect: createSession', action)),
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			switchMap(([action, authLoginState]) =>
				this.sessionService
					.CreateSession(
						action.params,
						authLoginState,
						action.oldSessionID,
						action.url,
						action.referrer
					)
					.pipe(
						map((sessionData) => createSessionSuccess({ sessionID: sessionData.sessionID })),
						catchError((error: any) => {
							const errorMessage: ErrorMessage = decodeError(error, this.translate);
							return of(createSessionFailure({ error: errorMessage }));
						})
					)
			)
		)
	);

	createSessionSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(createSessionSuccess),
				tap((sessionData) => {
					this.logService.info('Effect: createSessionSuccess');
					this.storageService.setItem('SESSIONID', sessionData.sessionID);
				})
			),
		{ dispatch: false }
	);

	createFailure = createEffect(
		() =>
			this.actions$.pipe(
				ofType(createSessionFailure),
				tap(() => this.logService.info('Effect: createSessionFailure', undefined))
			),
		{ dispatch: false }
	);

  createHardLoginSession$ = createEffect(() =>
		this.actions$.pipe(
			ofType(createHardLoginSession),
			getOneAndWaitforLoaded(this.store),
			tap((action) => this.logService.info('Effect: createHardLoginSession', action)),
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			switchMap(([action, authLoginState]) =>
				this.sessionService
					.CreateSession(
						action.params,
						authLoginState,
						action.oldSessionID,
						action.url,
						action.referrer
					)
					.pipe(
						map((sessionData) => createHardLoginSessionSuccess({ sessionID: sessionData.sessionID })),
						catchError((error: any) => {
							const errorMessage: ErrorMessage = decodeError(error, this.translate);
							return of(createHardLoginSessionFailure({ error: errorMessage }));
						})
					)
			)
		)
	);


  createHardLoginSessionSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(createHardLoginSessionSuccess),
				tap((sessionData) => {
					this.logService.info('Effect: createHardLoginSessionSuccess');
					this.storageService.setItem('SESSIONID', sessionData.sessionID);
          // create click_log on login success
					const clickLog: ClickLog = <ClickLog>{};
					clickLog.clickLogOperationID = ClickOperation.Login;
					clickLog.content = '';
					clickLog.message = 'Login OK';
					clickLog.method = 'POST';
					clickLog.outcome = true;
					this.store.dispatch(saveClickLog({ clickLog }));
				})
			),
      { dispatch: false }
	);

	createHardLoginSessionFailure = createEffect(
		() =>
			this.actions$.pipe(
				ofType(createHardLoginSessionFailure),
				tap(() => this.logService.info('Effect: createHardLoginSessionFailure', undefined))
			),
		{ dispatch: false }
	);


	constructor(
		private actions$: Actions,
		private store: Store,
		private logService: LogService,
		private sessionService: SessionService,
		private storageService: LocalStorageService,
    private translate: TranslateService
	) {}
}
