import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError,  map,  switchMap, tap } from 'rxjs/operators';
import { LogService } from '../../core/log/log.service';
import { onlyWhenAuthenticated, onlyWhenOnline } from '../../shared/util/operators';
import { ErrorMessage } from '../error/error-message.model';
import { manageError } from '../error/error.util';
import { PollService } from './poll-smart.service';
import { getPoll, getPollFailure, getPollSuccess, savePoll, savePollFailure, savePollSuccess, undoPoll, undoPollFailure, undoPollSuccess } from './poll-smart.actions';
import { Poll } from './poll-smart.model';
import { selectAuthLoginState } from '../../core/auth/auth.selectors';

@Injectable({ providedIn: 'root' })
export class PollEffects {
	getPoll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(getPoll),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			tap((action) => this.logService.info('Effect: getPoll', action)),
			switchMap((action) =>
				this.pollService.getPoll(action.pollId,action.userId).pipe(
					tap((polls: Poll) =>
						this.logService.infoDebug('Ho ricevuto i dati dei poll: ', undefined, polls)
					),
					map((pollData: Poll) => getPollSuccess({pollData: pollData})),
					catchError((error: ErrorMessage) => of(getPollFailure({ error })))
				)
			)
		)
	);


	getPollFailures$ = createEffect(() =>
		this.actions$.pipe(
			ofType(getPollFailure),
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			tap((action) => this.logService.info('Effect: getPollFailure ', action)),
			map(([{ error }, authLoginState]) => manageError(error, authLoginState))
		)
	);

  savePoll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(savePoll),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			tap((action) => this.logService.info('Effect: savePoll', action)),
			switchMap((action) =>
				this.pollService.savePoll(action.pollQuestionAnswer,action.userId,action.pollId).pipe(
					tap(() =>
						this.logService.infoDebug('Saved Poll')
					),
					map((pollData: Poll) => savePollSuccess({pollData: pollData})),
					catchError((error: ErrorMessage) => of(savePollFailure({ error })))
				)
			)
		)
	);

  undoPoll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(undoPoll),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			tap((action) => this.logService.info('Effect: undoPoll', action)),
			switchMap((action) =>
				this.pollService.undoPoll(action.userId,action.pollId).pipe(
					tap(() =>
						this.logService.infoDebug('Undo Poll')
					),
					map((pollData: Poll) => undoPollSuccess({pollData: pollData})),
					catchError((error: ErrorMessage) => of(undoPollFailure({ error })))
				)
			)
		)
	);


	constructor(
		private actions$: Actions,
		private logService: LogService,
		private pollService: PollService,
		private store: Store
	) {}
}
