import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { catchError, concatMap, map, of, switchMap, tap } from 'rxjs';
import { selectAuthLoginState } from '../../core/auth/auth.selectors';
import { LogService } from '../../core/log/log.service';
import {
	onlyWhenAuthenticated,
	onlyWhenOnline,
	//onlyWhenSession
} from '../../shared/util/operators';
import { Content } from '../content/content.model';
import { ContentService } from '../content/content.service';
import { manageError } from '../error/error.util';
import { selectUserId } from '../user/user.selectors';
import {
	loadAifa,
	loadAifaFailure,
	loadAifaSuccess,
	loadFocus,
	loadFocusListFailure,
	loadFocusListSuccess,
	loadFocusMainFailure,
	loadFocusMainSuccess,
	loadFocusVideo,
	loadFocusVideoListFailure,
	loadFocusVideoListSuccess,
	loadFocusVideoMainFailure,
	loadFocusVideoMainSuccess,
	loadHomeWidgets,
	loadInsights,
	loadInsightsFailure,
	loadInsightsSuccess,
	loadInstitutions,
	loadInstitutionsFailure,
	loadInstitutionsSuccess,
	loadInteresting,
	loadInterestingFailure,
	loadInterestingSuccess,
	loadLatestNews,
	loadLatestNewsFailure,
	loadLatestNewsSuccess,
	loadLatestVideos,
	loadLatestVideosFailure,
	loadLatestVideosSuccess,
	loadMainHighlight,
	loadMainHighlightFailure,
	loadMainHighlightSuccess,
	loadMedlex,
	loadMedlexFailure,
	loadMedlexSuccess,
	loadMinisterialBulletin,
	loadMinisterialBulletinFailure,
	loadMinisterialBulletinSuccess,
	loadMostRead,
	loadMostReadFailure,
	loadMostReadSuccess,
	loadRelated,
	loadRelatedFailure,
	loadRelatedSuccess
} from './widget.actions';

const ARTICLE_TPL_DESCRIPTION = 'articolo';
const VIDEO_TPL_DESCRIPTION = 'video';

const MEDLEX_ID_CATEGORIE = '58';
const BULLETIN_ID_CATEGORIE = '66';
const AIFA_ID_CATEGORIE = '65';
const INSTITUTIONS_ID_CATEGORIE = '59,60,62';
const INTERESTING_ID_CATEGORIE = '44';

const HIGHLIGHT_ID_RUBRICA = 52;
const FOCUS_MEDICINA_ID_RUBRICA = 53;
const FOCUS_VIDEO_ID_RUBRICA = 54;
const INSIGHTS_ID_RUBRICA = 52;

@Injectable({ providedIn: 'root' })
export class WidgetEffects {
	constructor(
		private actions$: Actions,
		private store: Store,
		private contentService: ContentService,
		private logService: LogService
	) {}

	// Rubriche
	loadHomeWidgets$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadHomeWidgets),
			tap((action) => this.logService.info('Effect: loadTopSectionHome', action)),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			//onlyWhenSession(this.store),
			concatLatestFrom(() => this.store.select(selectUserId)),
			concatMap(([, userId]) => [
				loadMedlex({ userId }),
				loadMinisterialBulletin({ userId }),
				loadAifa({ userId }),
				loadInstitutions({ userId }),
				loadMainHighlight({ userId }),
				loadMostRead({ userId }),
				loadLatestNews({ userId, fromWhere: 0, howMany: 6 }),
				loadLatestVideos({ userId }),
				loadFocus({ userId }),
				loadFocusVideo({ userId }),
				loadInsights({ userId })
			])
		)
	);

	loadMedlex$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadMedlex),
			tap((action) => this.logService.info('Effect: loadMedlex', action)),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			//onlyWhenSession(this.store),
			switchMap(({ userId }) =>
				this.contentService
					.loadContentsCorrelatiByCategorieUrl(
						ARTICLE_TPL_DESCRIPTION,
						MEDLEX_ID_CATEGORIE,
						userId,
						0,
						0,
						1
					)
					.pipe(
						tap((response: Content[]) =>
							this.logService.infoDebug('Effect: loadContentMedlex data received: ', response)
						),
						map((loadedContent: Content[]) => loadMedlexSuccess({ medlexData: loadedContent })),
						catchError((error) => of(loadMedlexFailure({ error })))
					)
			)
		)
	);

	loadMinisterialBulletin$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadMinisterialBulletin),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			//onlyWhenSession(this.store),
			tap((action) => this.logService.info('Effect: loadMinisterialBulletin', action)),
			switchMap(({ userId }) =>
				this.contentService
					.loadContentsCorrelatiByCategorieUrl(
						ARTICLE_TPL_DESCRIPTION,
						BULLETIN_ID_CATEGORIE,
						userId,
						0,
						0,
						1
					)
					.pipe(
						tap((response: Content[]) =>
							this.logService.infoDebug('Effect: loadMinisterialBulletin data received: ', response)
						),
						map((loadedContent: Content[]) =>
							loadMinisterialBulletinSuccess({
								ministerialBulletinData: loadedContent
							})
						),
						catchError((error) => of(loadMinisterialBulletinFailure({ error })))
					)
			)
		)
	);

	loadAifa$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadAifa),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			//onlyWhenSession(this.store),
			tap((action) => this.logService.info('Effect: loadAifa', action)),
			switchMap(({ userId }) =>
				this.contentService
					.loadContentsCorrelatiByCategorieUrl(
						ARTICLE_TPL_DESCRIPTION,
						AIFA_ID_CATEGORIE,
						userId,
						0,
						0,
						1
					)
					.pipe(
						tap((response: Content[]) =>
							this.logService.infoDebug('Effect: loadAifa data received: ', response)
						),
						map((loadedContent: Content[]) => loadAifaSuccess({ aifaData: loadedContent })),
						catchError((error) => of(loadAifaFailure({ error })))
					)
			)
		)
	);

	loadIstitutions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadInstitutions),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			//onlyWhenSession(this.store),
			tap((action) => this.logService.info('Effect: loadIstitutions', action)),
			switchMap(({ userId }) =>
				this.contentService
					.loadContentsCorrelatiByCategorieUrl(
						ARTICLE_TPL_DESCRIPTION,
						INSTITUTIONS_ID_CATEGORIE,
						userId,
						0,
						0,
						1
					)
					.pipe(
						tap((response: Content[]) =>
							this.logService.infoDebug('Effect: loadIstitutions data received: ', response)
						),
						map((loadedContent: Content[]) =>
							loadInstitutionsSuccess({ institutionsData: loadedContent })
						),
						catchError((error) => of(loadInstitutionsFailure({ error })))
					)
			)
		)
	);

	loadMainHighlight$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadMainHighlight),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			//onlyWhenSession(this.store),
			tap((action) => this.logService.info('Effect: loadMainHighlight', action)),
			switchMap(({ userId }) =>
				this.contentService
					.loadHighlightByIdRubricaEvidenza(
						HIGHLIGHT_ID_RUBRICA,
						0,
						userId,
						ARTICLE_TPL_DESCRIPTION,
						0
					)
					.pipe(
						tap((response: Content) =>
							this.logService.infoDebug('Effect: loadMainHighlight data received: ', response)
						),
						map((loadedContent: Content) =>
							loadMainHighlightSuccess({ highlightData: loadedContent })
						),
						catchError((error) => of(loadMainHighlightFailure({ error })))
					)
			)
		)
	);

	loadMostRead$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadMostRead),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			//onlyWhenSession(this.store),
			tap((action) => this.logService.info('Effect: loadMostRead', action)),
			switchMap(({ userId }) =>
				this.contentService.loadContentMostRead(userId, 0, 4).pipe(
					tap((response: Content[]) =>
						this.logService.infoDebug('Effect: loadMostRead data received: ', undefined, response)
					),
					map((mostReadData: Content[]) => loadMostReadSuccess({ mostReadData })),
					catchError((error) => of(loadMostReadFailure({ error })))
				)
			)
		)
	);

	loadLatestNews$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadLatestNews),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			//onlyWhenSession(this.store),
			tap((action) => this.logService.info('Effect: loadLatestNews', action)),
			switchMap((action) =>
				this.contentService
					.loadContentLatestNews(action.userId, action.fromWhere, action.howMany)
					.pipe(
						tap((response: Content[]) =>
							this.logService.infoDebug(
								'Effect: loadLatestNews data received: ',
								undefined,
								response
							)
						),
						map((latestNewsData: Content[]) => loadLatestNewsSuccess({ latestNewsData })),
						catchError((error) => of(loadLatestNewsFailure({ error })))
					)
			)
		)
	);

	loadRelated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadRelated),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			//onlyWhenSession(this.store),
			tap((action) => this.logService.info('Effect: loadRelated', action)),
			switchMap(({ category, template, contentId, userId }) =>
				this.contentService
					.loadContentRelated(category, template, contentId, 93658, userId, 0, 4)
					.pipe(
						tap((response: Content[]) =>
							this.logService.infoDebug('Effect: loadRelated data received: ', undefined, response)
						),
						map((relatedData: Content[]) => loadRelatedSuccess({ relatedData })),
						catchError((error) => of(loadRelatedFailure({ error })))
					)
			)
		)
	);

	loadInteresting$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadInteresting),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			//onlyWhenSession(this.store),
			tap((action) => this.logService.info('Effect: loadInteresting', action)),
			switchMap(({ userId, category, contentId }) =>
				this.contentService
					.loadContentsCorrelatiByCategorieUrl(
						ARTICLE_TPL_DESCRIPTION,
						// INTERESTING_ID_CATEGORIE,
						category,
						userId,
						contentId,
						0,
						4
					)
					.pipe(
						tap((response: Content[]) =>
							this.logService.infoDebug(
								'Effect: loadInteresting data received: ',
								undefined,
								response
							)
						),
						map((interestingData: Content[]) => loadInterestingSuccess({ interestingData })),
						catchError((error) => of(loadInterestingFailure({ error })))
					)
			)
		)
	);

	loadLatestVideos$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadLatestVideos),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			//onlyWhenSession(this.store),
			tap((action) => this.logService.info('Effect: loadLatestVideos', action)),
			switchMap(({ userId }) =>
				this.contentService.loadContentLatestVideos(userId, 0, 3).pipe(
					tap((contentLatestVideosData: Content[]) =>
						this.logService.infoDebug(
							'Effect: loadLatestVideos data received: ',
							undefined,
							contentLatestVideosData
						)
					),
					map((latestVideosData: Content[]) => loadLatestVideosSuccess({ latestVideosData })),
					catchError((error) => of(loadLatestVideosFailure({ error })))
				)
			)
		)
	);

	loadFocusMain$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadFocus),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			//onlyWhenSession(this.store),
			tap((action) => this.logService.info('Effect: loadFocusMain', action)),
			switchMap(({ userId }) =>
				this.contentService
					.loadHighlightByIdRubricaEvidenza(
						FOCUS_MEDICINA_ID_RUBRICA,
						0,
						userId,
						ARTICLE_TPL_DESCRIPTION,
						0
					)
					.pipe(
						tap((response: Content) =>
							this.logService.infoDebug(
								'Effect: loadFocusMain data received: ',
								undefined,
								response
							)
						),
						map((focusMainData: Content) => loadFocusMainSuccess({ focusMainData })),
						catchError((error) => of(loadFocusMainFailure({ error })))
					)
			)
		)
	);

	loadFocusList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadFocus),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			//onlyWhenSession(this.store),
			tap((action) => this.logService.info('Effect: loadFocusList', action)),
			switchMap(({ userId }) =>
				this.contentService
					.loadHighlightByIdRubrica(
						FOCUS_MEDICINA_ID_RUBRICA,
						0,
						userId,
						ARTICLE_TPL_DESCRIPTION,
						1,
						4
					)
					.pipe(
						tap((response: Content[]) =>
							this.logService.infoDebug(
								'Effect: loadFocusList data received: ',
								undefined,
								response
							)
						),
						map((focusListData: Content[]) => loadFocusListSuccess({ focusListData })),
						catchError((error) => of(loadFocusListFailure({ error })))
					)
			)
		)
	);

	loadFocusVideoMain$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadFocusVideo),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			//onlyWhenSession(this.store),
			tap((action) => this.logService.info('Effect: loadFocusVideoMain', action)),
			switchMap(({ userId }) =>
				this.contentService
					.loadHighlightByIdRubricaEvidenza(
						FOCUS_VIDEO_ID_RUBRICA,
						0,
						userId,
						VIDEO_TPL_DESCRIPTION,
						0
					)
					.pipe(
						tap((response: Content) =>
							this.logService.infoDebug(
								'Effect: loadFocusVideoMain data received: ',
								undefined,
								response
							)
						),
						map((focusVideoMainData: Content) => loadFocusVideoMainSuccess({ focusVideoMainData })),
						catchError((error) => of(loadFocusVideoMainFailure({ error })))
					)
			)
		)
	);

	loadFocusVideoList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadFocusVideo),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			//onlyWhenSession(this.store),
			tap((action) => this.logService.info('Effect: loadFocusVideoList', action)),
			switchMap(({ userId }) =>
				this.contentService
					.loadHighlightByIdRubrica(FOCUS_VIDEO_ID_RUBRICA, 0, userId, VIDEO_TPL_DESCRIPTION, 1, 4)
					.pipe(
						tap((response: Content[]) =>
							this.logService.infoDebug(
								'Effect: loadFocusVideoList data received: ',
								undefined,
								response
							)
						),
						map((focusVideoListData: Content[]) =>
							loadFocusVideoListSuccess({ focusVideoListData })
						),
						catchError((error) => of(loadFocusVideoListFailure({ error })))
					)
			)
		)
	);

	loadInsights$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadInsights),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			//onlyWhenSession(this.store),
			tap((action) => this.logService.info('Effect: loadInsights', action)),
			switchMap(({ userId }) =>
				this.contentService
					.loadHighlightByIdRubrica(INSIGHTS_ID_RUBRICA, 0, userId, ARTICLE_TPL_DESCRIPTION, 1, 8)
					.pipe(
						tap((response: Content[]) =>
							this.logService.infoDebug('Effect: loadInsights data received: ', undefined, response)
						),
						map((insightsData: Content[]) => loadInsightsSuccess({ insightsData })),
						catchError((error) => of(loadInsightsFailure({ error })))
					)
			)
		)
	);

	// Well-effect to group every error
	loadWidgetFailures$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				loadMedlexFailure,
				loadMinisterialBulletinFailure,
				loadAifaFailure,
				loadInstitutionsFailure,
				loadMostReadFailure,
				loadLatestNewsFailure,
				loadLatestVideosFailure,
				loadMainHighlightFailure,
				loadFocusMainFailure,
				loadFocusListFailure,
				loadFocusVideoMainFailure,
				loadFocusVideoListFailure,
				loadInsightsFailure,
				loadInterestingFailure,
				loadRelatedFailure
			),
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			tap(([payload, _]) => this.logService.info('Effect: loadWidgetsFailure', payload.error)),
			map(([{ error }, authLoginState]) => manageError(error, authLoginState))
		)
	);
}
