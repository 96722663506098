import { Injectable } from '@angular/core';
import { CallService } from '../../core/call-service/browser/call.service';
import { LogService } from '../../core/log/log.service';
import {getPollUrl, savePollUrl, undoPollUrl} from '../../core/router/routes-provider';
import { Poll, PollQuestionAnswer } from './poll-smart.model';
@Injectable({
	providedIn: 'root'
})
export class PollService {
	constructor(private callService: CallService, private logService: LogService) {}

	getPoll(pollId: number, userId: number) {
		const urltoCall = getPollUrl(pollId, userId);
		this.logService.info('Calling ', urltoCall);
		const result = this.callService.CallApi<Poll>('GET', urltoCall);
		return result;
	}

  savePoll(pollQuestionAnswer: PollQuestionAnswer[], userId:number, pollId:number) {

    const urlToCall = savePollUrl();
		this.logService.info('Calling ', urlToCall);
    const userPoll = { pollQuestionAnswer: pollQuestionAnswer, userId: userId , pollId: pollId };

		const result = this.callService.CallApi(
			'POST',
			urlToCall,
			JSON.stringify(userPoll)
		);
		return result;
	}

  undoPoll(userId:number, pollId:number) {
		const urltoCall = undoPollUrl(userId,pollId);
		this.logService.info('Calling ', urltoCall);
		const result = this.callService.CallApi<Poll>('GET', urltoCall);
		return result;
	}

}
