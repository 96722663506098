import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { EnvironmentService } from '../../../environments/environment.service';
import { selectAuthLoginState } from '../../core/auth/auth.selectors';
import { LogService } from '../../core/log/log.service';
import { Content } from '../../domain/content/content.model';
import { ContentService } from '../../domain/content/content.service';
import { ErrorMessage } from '../../domain/error/error-message.model';
import { selectUserId, selectUserIsActivated } from '../../domain/user/user.selectors';
import { getPermission } from '../util/util';
import {
	checkPermissionDialog,
	closeDialog,
	errorDialog,
	evaluatePermissionDialog,
	loadDialog,
	openDialog
} from './dn-dialog.actions';
import { PermissionDialogComponent } from './permission-dialog/permission-dialog.component';
import {
	ContentPermission,
	PermissionDialogData,
	PermissionVisibility,
	PermissionVisualizationType
} from './permission-dialog/permission.model';
import { showSnackbar } from '../../core/notifications/notification.actions';
import { NotificationService } from '../../core/notifications/notification.service';
import { AuthLoginState } from '../../core/auth/auth.models';
import { TranslateService } from '@ngx-translate/core';
import { SigninDialogComponent } from './signin-dialog/signin-dialog.component';
import { clearContainer } from '../../domain/dynamic-container/dynamic-container.actions';

@Injectable({ providedIn: 'root' })
export class DialogEffects {
	constructor(
		private actions$: Actions,
		private store: Store,
		private dialogRef: MatDialog,
		private logService: LogService,
		private notificationService: NotificationService,
		private contentService: ContentService,
		private envService: EnvironmentService,
		private translateService: TranslateService
	) {}

	loadDialog$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadDialog),
			tap((payload) => this.logService.info('Effect: loadDialog ' + JSON.stringify(payload))),
			concatLatestFrom(() => this.store.select(selectUserId)),
			switchMap(([payload, userId]) =>
				this.contentService.loadContentById(userId, payload.contentId, payload.template).pipe(
					tap((response) =>
						this.logService.infoDebug('Effect: loadDialog data received --> ', response)
					),
					concatLatestFrom(() => this.store.select(selectAuthLoginState)),

					map(([response, loginState]) => {
						if (
							getPermission(
								PermissionVisibility.RESTRICTIONS,
								loginState,
								response.abilitato,
								true
							) !== PermissionVisualizationType.PermissionAllowed
						) {
							return openDialog({
								content: undefined,
								urlToDownload: undefined,
								urlWithContext: undefined,
								componentType: PermissionDialogComponent,
								disableClose: true,
								panelClass: 'dn-dialog-small',
								data: <PermissionDialogData>{
									templateVisibility: PermissionVisibility.RESTRICTIONS,
									callbackUrl: payload.contextUrl + response.permalink,
									showClose: true,
									selectorAbilitato: of(response.abilitato)
								}
							});
						} else {
							return openDialog({
								content: response,
								urlToDownload: this.envService.contentPath + response.url,
								urlWithContext: payload.contextUrl + response.permalink,
								data: payload.dataDialog,
								componentType: payload.componentType
							});
						}
					}),
					catchError((error: ErrorMessage) => of(errorDialog({ error })))
				)
			)
		)
	);

	errorDialog$ = createEffect(() =>
		this.actions$.pipe(
			ofType(errorDialog),
			tap((action) => this.logService.info('Effect: errorDialog', action)),
			map((action) => action.error),
			map((error) =>
				showSnackbar({
					payload: this.notificationService.getNotificationFromError(error)
				})
			)
		)
	);

	openDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(openDialog),
				tap((payload) => this.logService.info('Effect: openDialog')),
				tap(
					({
						content,
						urlToDownload,
						urlWithContext,
						componentType,
						disableClose,
						data,
						panelClass
					}) => {
						// this.dialogRef.openDialogs.length === 0
						// 	?
						this.dialogRef.open(componentType, {
							closeOnNavigation: true,
							disableClose: disableClose,
							panelClass: panelClass ? panelClass : 'dn-dialog-large',
							data: {
								content: content,
								urlToDownload: urlToDownload,
								urlWithContext: urlWithContext,
								...data
							}
						});
						// : undefined
					}
				)
			),
		{ dispatch: false }
	);

	closeDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(closeDialog),
				tap((action) => this.logService.info('Effect: DialogClosed', action)),
				tap(() => this.dialogRef.openDialogs.pop()?.close())
			),
		{ dispatch: false }
	);

	checkPermissionDialog$ = createEffect(() =>
		this.actions$.pipe(
			ofType(checkPermissionDialog),
			tap((action) => this.logService.info('Effect: checkPermissionDialog', action)),
			concatLatestFrom(({ selectorAbilitato: selectorAbilitato }) =>
				selectorAbilitato ? selectorAbilitato : of(undefined)
			),
			concatLatestFrom(() => this.store.select(selectUserIsActivated)),
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			filter(
				([
					[[{ templateVisibility: templateVisibility }, isAbilitato], isUserActivated],
					authLoginState
				]) =>
					getPermission(templateVisibility, authLoginState, isAbilitato, isUserActivated) !==
					PermissionVisualizationType.PermissionAllowed
			),
			map(
				([
					[
						[
							{
								templateVisibility: templateVisibility,
								callbackUrl: callbackUrl,
								selectorAbilitato: selectorAbilitato
							},
							isAbilitato
						],
						isUserActivated
					],
					authLoginState
				]) =>
					openDialog({
						content: undefined,
						urlWithContext: undefined,
						urlToDownload: undefined,
						componentType: PermissionDialogComponent,
						disableClose: true,
						panelClass: 'dn-dialog-small',
						data: {
							templateVisibility: templateVisibility,
							selectorAbilitato: selectorAbilitato,
							showClose: true,
							callbackUrl: callbackUrl
						}
					})
			)
		)
	);

	evaluatePermissionDialog$ = createEffect(() =>
		this.actions$.pipe(
			ofType(evaluatePermissionDialog),
			tap((action) => {
				this.logService.info('Effect: evaluatePermissionDialog ', action.type);
			}),
			concatLatestFrom(({ abilitatoSelector }) => [
				abilitatoSelector ? abilitatoSelector : of(undefined),
				this.store.select(selectUserIsActivated),
				this.store.select(selectAuthLoginState)
			]),
			map(
				([
					{
						templateVisibility: templateVisibility,
						abilitatoSelector: abilitatoSelector,
						callbackUrl: callbackUrl,
						showClose: showClose,
						nextAction: nextAction
					},
					isAbilitato,
					isUserActivated,
					authLoginState
				]) => {
					if (
						getPermission(templateVisibility, authLoginState, isAbilitato, isUserActivated) !==
						PermissionVisualizationType.PermissionAllowed
					) {
						return openDialog({
							content: undefined,
							urlToDownload: undefined,
							urlWithContext: undefined,
							componentType: PermissionDialogComponent,
							disableClose: true,
							panelClass: 'dn-dialog-small',
							data: {
								templateVisibility: templateVisibility,
								callbackUrl: callbackUrl,
								selectorAbilitato: abilitatoSelector,
								showClose: showClose,
								nextAction
							}
						});
					} else {
						return nextAction;
					}
				}
			)
		)
	);
}
