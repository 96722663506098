/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, throttleTime, withLatestFrom } from 'rxjs/operators';
import {
	authGuestLogin,
	authHardLoginSuccess,
	authUserLoadedHard,
	authUserLoadedSoft
} from '../../core/auth/auth.actions';
import { ServerAuthData } from '../../core/auth/auth.models';
import { selectAuthLoginState } from '../../core/auth/auth.selectors';
import { saveClickLog } from '../../core/clicks/click.actions';
import { ClickLog, ClickOperation } from '../../core/clicks/click.models';
import { LocalStorageService } from '../../core/local-storage/local-storage.service';
import { LogService } from '../../core/log/log.service';
import { showSnackbar } from '../../core/notifications/notification.actions';
import { NotificationService } from '../../core/notifications/notification.service';
import { navigateHome, navigateToPage } from '../../core/router/routes.actions';
import {
	closeDialog,
	openDialog,
	updatePassword,
	updatePasswordFailure
} from '../../shared/dialog/dn-dialog.actions';
import { WallDialogComponent } from '../../shared/dialog/wall-dialog/wall-dialog.component';
import { UserFormType } from '../../shared/user-form/user-form.model';
import { getOneAndWaitforLoaded, onlyWhenOnline } from '../../shared/util/operators';
import { ErrorMessage } from '../error/error-message.model';
import { manageError } from '../error/error.util';
import {
	activateUser,
	activateUserFailure,
	loadUser,
	loadUserFailure,
	loadUserGuest,
	loadUserSoft,
	loadUserSoftSuccess,
	loadUserSuccess,
	registerUser,
	registerUserFailure,
	registerUserSuccess,
	resendActivationMail,
	resendActivationMailSuccess,
	retrievePassword,
	retrievePasswordFailure,
	retrievePasswordSuccess,
	saveUserInStore,
	updateUser,
	updateUserFailure,
	updateUserSuccess
} from './user.actions';
import {  ResendEmailDTO, User, UserDTO } from './user.model';
import { selectUser, selectUserError } from './user.selectors';
import { UserService } from './user.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { getTemplateFromDescription } from '../template-ct/template-ct.model';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';

@Injectable({ providedIn: 'root' })
export class UserEffects {
	saveUserInStore$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(saveUserInStore),
				tap((action) => this.logService.info('Effect: saveUserInStore ', action)),
				map((payload) => payload.utente),
				tap((user) => {
					this.localStorageService.setItem('USER', { idAnagrafica: user.idAnagrafica });
				})
			),
		{ dispatch: false }
	);

	setLogServiceUser$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(loadUserSuccess, loadUserSoftSuccess, loadUserGuest),
				map((payload) => (payload.utente ? payload.utente.idAnagrafica : -1)),
				tap((idAnagrafica) => {
					this.logService.idAnagrafica = idAnagrafica;
				})
			),
		{ dispatch: false }
	);

	activateUser$ = createEffect(() =>{
    let params: ParamMap;
    let oldSessionID: number;
    let url: string;
    let referrer: string
		return this.actions$.pipe(
			ofType(activateUser),
			tap((action) => this.logService.info('Effect: activateUser ', action)),
      tap((action) => {
        params = action.params;
        oldSessionID = action.oldSessionID;
        url = action.url;
        referrer = action.referrer;
        })  ,
			onlyWhenOnline(this.store),
			switchMap(({ token, callbackUrl }) =>
				this.userService.activateUser(token).pipe(
					tap((serverAuthData: ServerAuthData) => {
						this.logService.infoDebug('Effect: activateUser data received --> ', serverAuthData);
					}),

					concatMap((serverAuthData: ServerAuthData) => [
						authHardLoginSuccess({ serverAuthData: serverAuthData,params: params,oldSessionID: oldSessionID,url: url,referrer: referrer }),
						// navigateHome(),
						showSnackbar({
							payload: this.notificationService.getOkNotification(
								0,
								'Utente confermato con successo!'
							)
						})
					]),
					catchError((error: ErrorMessage) => of(activateUserFailure({ error: error, callbackUrl: callbackUrl })))
				)
			)
		)}
	);

	loadUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadUser),
			tap((action) => this.logService.info('Effect: loadUser ', action)),

			onlyWhenOnline(this.store),
			getOneAndWaitforLoaded(this.store),
			switchMap((action) =>
				this.userService.loadUser(action.idAnagrafica).pipe(
					tap((data) => this.logService.infoDebug('Effect: loadUser data received --> ', data)),

					map((user) => loadUserSuccess({ utente: user })),
					catchError((error: ErrorMessage) => of(loadUserFailure({ error: error })))
				)
			)
		)
	);

	loadUserSoft$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadUserSoft),
			tap((action) => this.logService.info('Effect: loadUserSoft', action)),
			onlyWhenOnline(this.store),
			getOneAndWaitforLoaded(this.store),
			switchMap((action) =>
				this.userService.loadUser(action.idAnagrafica).pipe(
					tap((data) => this.logService.infoDebug('Effect: loadUserSoft data received --> ', data)),

					map((user) => loadUserSoftSuccess({ utente: user })),
					catchError((error: ErrorMessage) => of(loadUserFailure({ error: error })))
				)
			)
		)
	);

	loadUserSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadUserSuccess),
			tap((action) => {
				this.logService.info('Effect: loadUserSuccess ', action);
			}),
			map((payload) => payload.utente),
      /*
      In an NgRx effect, the router might not have finished its navigation when the effect runs, which is why you're seeing the URL as /.
      To get the URL in an effect, you can use the ROUTER_NAVIGATION action, which is dispatched by @ngrx/router-store after a successful navigation.
      This action contains the router state, which includes the URL. First, make sure you have @ngrx/router-store set up in your application.
      Then, you can listen for the ROUTER_NAVIGATION action in your effect
      */
      withLatestFrom(this.actions$.pipe(ofType(ROUTER_NAVIGATION))),
			concatMap(([user,routerAction]) => {
         // set user local storage used by drclick_soa
         this.localStorageService.setItem('USER', { idAnagrafica: user.idAnagrafica });
        // i gruppi da mostrare nel wall sono di default quelli con showGroup = true
        // se mi trovo in una pagina relativa ad un templare con extendedProfile, aggiungo anche i gruppi relativi a quell'extendedProfile
        const showGroups = user.listaGruppi.filter(group => group.showGroup)
        const currentUrl = (routerAction as RouterNavigationAction).payload.routerState.url;
        const extendedProfile=  getTemplateFromDescription(currentUrl.split('/')[1])?.extendedProfile;
        if(extendedProfile){
          const extendedProfileGroup = user.listaGruppi.filter(group => group.group === extendedProfile);
          extendedProfileGroup.forEach(group => showGroups.push(group));
        }

        // a questo punto ho a disposizione tutti i gruppi da mostrare nel wall
        // il wall non deve aprirsi sulla pagina di privacy e di condizioni di utilizzo
          if (showGroups.length > 0 && currentUrl.split('/')[1] !== 'privacy' && currentUrl.split('/')[1] !== 'condizioni-utilizzo'){
					return [
						authUserLoadedHard(),
						openDialog({
							content: undefined,
							urlToDownload: undefined,
							urlWithContext: undefined,
							componentType: WallDialogComponent,
							data: { listaGruppi: showGroups }
						})
					];
				} else {
					return [
            authUserLoadedHard()];
				}
			})
		)
	);

	loadUserSoftSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadUserSoftSuccess),
			tap((action) => {
				this.logService.info('Effect: loadUserSoftSuccess', action);
			}),
			map((payload) => payload.utente),
			 /*
      In an NgRx effect, the router might not have finished its navigation when the effect runs, which is why you're seeing the URL as /.
      To get the URL in an effect, you can use the ROUTER_NAVIGATION action, which is dispatched by @ngrx/router-store after a successful navigation.
      This action contains the router state, which includes the URL. First, make sure you have @ngrx/router-store set up in your application.
      Then, you can listen for the ROUTER_NAVIGATION action in your effect
      */
      withLatestFrom(this.actions$.pipe(ofType(ROUTER_NAVIGATION))),
			concatMap(([user,routerAction]) => {
        // i gruppi da mostrare nel wall sono di default quelli con showGroup = true
        // se mi trovo in una pagina relativa ad un templare con extendedProfile, aggiungo anche i gruppi relativi a quell'extendedProfile
        const showGroups = user.listaGruppi.filter(group => group.showGroup)
        const currentUrl = (routerAction as RouterNavigationAction).payload.routerState.url;
        const extendedProfile=  getTemplateFromDescription(currentUrl.split('/')[1])?.extendedProfile;
        if(extendedProfile){
          const extendedProfileGroup = user.listaGruppi.filter(group => group.group === extendedProfile);
          extendedProfileGroup.forEach(group => showGroups.push(group));
        }

        // a questo pu nto ho a disposizione tutti i gruppi da mostrare nel wall
          if (showGroups.length > 0) {
					return [

						authUserLoadedSoft(),
						openDialog({
							content: undefined,
							urlToDownload: undefined,
							urlWithContext: undefined,
							componentType: WallDialogComponent,
							data: { listaGruppi: user.listaGruppi }
						})
					];
				} else {
					return [
            authUserLoadedSoft()];
				}
			})
		)
	);

	registerUser$ = createEffect(() => {
		let savedUser: User;
		const clickLog: ClickLog = <ClickLog>{};
    let params: ParamMap;
    let oldSessionID: number;
    let url: string;
    let referrer: string
		return this.actions$.pipe(
			ofType(registerUser),
			tap((action) => this.logService.info('Effect: registerUser', action)),
			tap((action) => {
        savedUser = action.newUser
        params = action.params;
        oldSessionID = action.oldSessionID;
        url = action.url;
        referrer = action.referrer;
        })  ,
			switchMap(({ newUser, queryParams }) =>
				this.userService.registerUser({ user: newUser, callbackUrl: queryParams.callbackUrl }).pipe(
					tap((serverAuthData: ServerAuthData) => {
						clickLog.clickLogOperationID = ClickOperation.Registrazione;
						clickLog.content = JSON.stringify(savedUser);
						clickLog.message = 'Registrazione OK';
						clickLog.method = 'POST';
						clickLog.outcome = true;
						this.logService.infoDebug('Effect: registerUser data received --> ', serverAuthData);

					}),
					concatMap((serverAuthData: ServerAuthData) => [
						authHardLoginSuccess({ serverAuthData: serverAuthData,params: params,oldSessionID: oldSessionID,url: url,referrer: referrer }),
						saveClickLog({ clickLog }),
						navigateToPage({ page: 'profile/activate', queryParams }),
						showSnackbar({
							payload: this.notificationService.getOkNotification(
								0,
								'Utente registrato con successo. Conferma il tuo account attraverso il collegamento inviato alla tua mail usata in fase di registrazione'
							)
						})
					]),
					catchError((error: ErrorMessage) => {
						clickLog.clickLogOperationID = ClickOperation.Registrazione;
						clickLog.content = JSON.stringify(savedUser);
						clickLog.message = error.messageDN;
						clickLog.method = 'POST';
						clickLog.outcome = false;
						this.store.dispatch(saveClickLog({ clickLog }));

						return of(registerUserFailure({ error }));
					})
				)
			)
		);
	});

	updateUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(updateUser),
			tap((action) => this.logService.info('Effect: updateUser ', action)),
			map((action) => action.newUser),
			switchMap((user) =>
				this.userService.updateUser(user).pipe(
					tap((returnedUser: UserDTO) =>
						this.logService.infoDebug('Effect: updateUser data received --> ', returnedUser)
					),
					map((returnedUser: UserDTO) => updateUserSuccess({ newUser: returnedUser })),
					catchError((error: ErrorMessage) => of(updateUserFailure({ error })))
				)
			)
		)
	);

	updateUserSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(updateUserSuccess),
			tap((action) => this.logService.info('Effect: updateUserSuccess ', action)),
			map(({ newUser }) => saveUserInStore({ utente: newUser })
      )
		)
	);

	resendActivationMail$ = createEffect(() =>
		this.actions$.pipe(
			ofType(resendActivationMail),
			throttleTime(5000),
			tap((action) => this.logService.info('Effect: resendActivationMail ', action)),
			concatLatestFrom(() => [
				this.store.select(selectUser),
				this.store.select(selectUserError),
				this.store.select(selectAuthLoginState)
			]),

			switchMap(([{ callbackUrl }, user, userError, authLoginState]) => {
				if (!this.areUserInfoValid(user, userError)) {
					throw new Error("Can't retrieve user infos to send an activation mail");
				} else {
					// if we are getting values from userError, this means that user is not valid, an so user.email doesn't exist. So the question mark

					return this.userService
						.resendActivationMail(<ResendEmailDTO>{
							email: user?.email || userError.hint.split('|')[0].split(':')[1],
							userId: user?.idAnagrafica || +userError.hint.split('|')[1].split(':')[1],
							callbackUrl: callbackUrl
						}
						)
				.pipe(
					tap((response) =>
						this.logService.infoDebug('Effect: resendActivationMailSuccess ', response)
					),
					map((response) => resendActivationMailSuccess()),
					catchError((error) => of(manageError(error, authLoginState)))
				);
}
			})
		)
	);

resendActivationMailSuccess$ = createEffect(() =>
	this.actions$.pipe(
		ofType(resendActivationMailSuccess),
		tap((action) => this.logService.info('Effect: resendActivationMailSuccess ', action)),
		map(() =>
			showSnackbar({
				payload: this.notificationService.getOkNotification(
					0,
					this.ts.instant('dottnet.message.activationMailSent')
				)
			})
		)
	)
);

retrievePassword$ = createEffect(() =>
	this.actions$.pipe(
		ofType(retrievePassword),
		onlyWhenOnline(this.store),
		tap((action) => this.logService.info('Effect: retrievePassword ', action)),

		switchMap(({ email }) =>
			this.userService.retrievePassword(email).pipe(
				tap((response) => this.logService.info('Effect: retrievePassword data --> ', response)),
				map(() => retrievePasswordSuccess()),
				catchError((error: ErrorMessage) => of(retrievePasswordFailure({ error })))
			)
		)
	)
);

retrievePasswordSuccess$ = createEffect(() =>
	this.actions$.pipe(
		ofType(retrievePasswordSuccess),
		tap((action) => this.logService.info('Effect: retrievePasswordSuccess ', action)),
		concatMap(() => [
			showSnackbar({
				payload: this.notificationService.getOkNotification(
					0,
					this.ts.instant('dottnet.message.retrievepassword')
				)
			}),
			closeDialog()
		])
	)
);

retrievePasswordFailure$ = createEffect(() =>
	this.actions$.pipe(
		ofType(retrievePasswordFailure),
		tap((action) => this.logService.info('Effect: retrievePasswordFailure', action)),
		map((action) => action.error),
		map((error) =>
			showSnackbar({
				payload: this.notificationService.getNotificationFromError(error)
			})
		)
	)
);

changePassword$ = createEffect(() =>
	this.actions$.pipe(
		ofType(updatePassword),
		onlyWhenOnline(this.store),
		// map((payload) => payload.email),
		switchMap((payload) =>
			this.userService.changePassword(payload.passwordChange).pipe(
				concatMap(() => [
					showSnackbar({
						payload: this.notificationService.getOkNotification(
							0,
							this.ts.instant('dottnet.message.changedpassword')
						)
					}),
					closeDialog()
				]),
				catchError((error: ErrorMessage) => of(updatePasswordFailure({ error })))
			)
		)
	)
);

changePasswordFailure$ = createEffect(() =>
	this.actions$.pipe(
		ofType(updatePasswordFailure),
		tap((action) => this.logService.info('Effect: changePasswordFailure', action)),
		map((action) => action.error),
		map((error) =>
			showSnackbar({
				payload: this.notificationService.getNotificationFromError(error)
			})
		)
	)
);

operationOnUserSuccess$ = createEffect(() =>
	this.actions$.pipe(
		ofType(updateUserSuccess, registerUserSuccess),
		tap((action) => this.logService.info('Effect: operationOnUserSuccess', action)),
		concatMap(() => [
			showSnackbar({
				payload: this.notificationService.getOkNotification()
			})
		])
	)
);

loadUserFailure$ = createEffect(() =>
	this.actions$.pipe(
		ofType(loadUserFailure),
		tap((action) => this.logService.info('Effect: loadUserFailure ', action)),
		concatLatestFrom(() => this.store.select(selectAuthLoginState)),
		map(([{ error }, authLoginState]) => manageError(error, authLoginState))
	)
);

activateUserFailure$ = createEffect(() =>
	this.actions$.pipe(
		ofType(activateUserFailure),
		tap(({ error }) => this.logService.info('Effect: activateUserFailure ', error)),
		concatMap(({ error, callbackUrl }) => [
			authGuestLogin(),
			navigateToPage({ page: '/profile/activate' }),
			resendActivationMail({ callbackUrl })
		])
	)
);

operationOnUserFailure$ = createEffect(() =>
	this.actions$.pipe(
		ofType(updateUserFailure, registerUserFailure),
		tap((action) => this.logService.info('Effect: operationOnUserFailure* ', action)),
		map((action) => action.error),
		map((error) =>
			showSnackbar({
				payload: this.notificationService.getNotificationFromError(error)
			})
		)
	)
);

	constructor(
		private actions$: Actions,
		private logService: LogService,
		private ts: TranslateService,
    private router: Router,
    public actRoute: ActivatedRoute,
		private userService: UserService,
		private notificationService: NotificationService,
		private localStorageService: LocalStorageService,
		private store: Store
	) {}

	// Checks if user has an email + id || user data coming from BE error
	// if jwt from softlogin is expired BE returns mail:current email|idAnagrafica:currentIdAdnagrafica
	// jwtsoftlogin.go riga 198
	// why? Don't know
	private areUserInfoValid(user: UserDTO, userError: ErrorMessage): boolean {
	if (userError) {
		// Split the inital string over pipe symbol and lower the case + trim
		const splittedHint = userError?.hint
			?.split('|')
			?.map((elem) => elem.toLowerCase())
			?.map((elem) => elem.trim());
		// Is the hint wrote in a known pattern
		const isHintPresent =
			splittedHint?.[0]?.includes('mail') && splittedHint?.[1]?.includes('idanagrafica');
		// Are the hint values actually there
		const isHintOk = !!splittedHint?.[0]?.split(':')?.[1] && !!splittedHint?.[1]?.split(':')?.[1];

		return isHintPresent && isHintOk;
	} else return !!user?.email && !!user?.idAnagrafica;
}
}
