/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DefaultUrlSerializer, PRIMARY_OUTLET, Router, UrlTree } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { fromEvent, of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { EnvironmentService } from '../../../environments/environment.service';
import { ErrorMessage, ErrorType } from '../../domain/error/error-message.model';
import { isErrorMessage, manageError } from '../../domain/error/error.util';
import { DottnetTemplate, TemplateCt } from '../../domain/template-ct/template-ct.model';
import { selectTemplateAll } from '../../domain/template-ct/template-ct.selectors';
import {
	onlyClient,
	onlyWhenAuthenticated,
	onlyWhenOnline,
	onlyWhenSession
} from '../../shared/util/operators';
import { selectAuthLoginState } from '../auth/auth.selectors';
import { LogService } from '../log/log.service';
import { selectSessionID } from '../session/session.selectors';
import {
	saveClickLog,
	saveClickLogFailure,
	saveClickLogSuccess,
	saveClick,
	saveClickFailure,
	saveClickSuccess
} from './click.actions';
import { ClickDetail, ClickLogID } from './click.models';
import { ClickService } from './click.service';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { isNumber, replaceAll } from '../../shared/util/util';

//  click to external sites
@Injectable({ providedIn: 'root' })
export class ClickEffects {
	constructor(
		private logService: LogService,
		private router: Router,
		private store: Store,
		private environmentService: EnvironmentService,
		private clickService: ClickService,
		private actions$: Actions,
		// Document represents the rendering context. When switching to browser, it coincides with the DOM
		@Inject(DOCUMENT) private ssrDocument: Document
	) {}

	saveClick$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveClick),
			tap((action) => this.logService.info('Effect: saveClick ', action)),
			filter((payload) => !!payload.clickTo),
			onlyWhenSession(this.store),
			onlyWhenAuthenticated(this.store),
			concatLatestFrom(() => [
				this.store.select(selectSessionID),
				this.store.select(selectTemplateAll())
			]),
			tap((event) => {
				this.logService.info('saveClick received event', event);
			}),

			map(([payload, sessionId, templateArray]) =>
				this.getClickParametersFromUrl(
					payload.clickTo,
					sessionId,
					templateArray,
					payload?.action ? payload.action : '',
					this.environmentService.clientDomain,
					payload?.context?.contextTypeID ? payload.context.contextTypeID : 0,
					payload?.context?.contextID ? payload.context.contextID : 0
				)
			),
			mergeMap((clickDetail) => {
				if (isErrorMessage(clickDetail)) {
					this.logService.error('Error generating click detail: ', clickDetail);
					return of(saveClickFailure({ error: clickDetail }));
				} else {
					this.logService.debug('Click detail generated: ', clickDetail);
					return this.clickService.CreatePageView(clickDetail).pipe(
						tap((response) => this.logService.infoDebug('Effect: saveClickSuccess ', response)),
						map(() => saveClickSuccess()),
						catchError((error: ErrorMessage) => of(saveClickFailure({ error })))
					);
				}
			})
		)
	);

	//  save failure
	saveClickFailure$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveClickFailure),
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			tap((action) => this.logService.info('Effect: saveClickFailure ', action)),
			map(([{ error }, authLoginState]) => manageError(error, authLoginState))
		)
	);

	saveExternalClicks = createEffect(() =>
		//  get all click events
		fromEvent(this.ssrDocument, 'click').pipe(
			tap((event) => this.logService.info('Effect: saveExternalClicks', event)),
			//  we just want PointerEvents, that is a complete touch or a complete click
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			filter((event) => event instanceof PointerEvent),
			//  at the moment, we only are getting click events on A and IMG tags, considering links are only there
			//  this could prove wrong, it has to be verified
			filter((event: PointerEvent) => {
				const htmlEvent: HTMLElement = <HTMLElement>event.target;
				if (
					htmlEvent.tagName &&
					(htmlEvent.tagName.toUpperCase() === 'A' || htmlEvent?.parentElement?.tagName === 'A')
				) {
					return true;
				} else {
					this.logService.trace('saveExternalclicks: not managed event', htmlEvent.parentElement);
					this.logService.trace(
						'saveExternalclicks: not managed event parent tagname',
						htmlEvent.parentElement?.tagName
							? htmlEvent.parentElement.tagName
							: 'tagName missing in parentelement'
					);
					return false;
				}
			}),
			map((pointerEvent) => {
				//  to page is in href, if tag is A, or in parent.href, if it's an  image
				let tagError: boolean = false;
				let htmlEvent: HTMLElement = <HTMLElement>pointerEvent.target;
				let clickTo: string = '';
				let contextTypeId: number = 0;
				let contextId: number = 0;

				let clickElement = {
					clickTo: clickTo,
					context: {
						contextID: contextId,
						contextTypeID: contextTypeId
					}
				};
				// if we are here, either tagname is A or parent tagname is A.
				// We need the A element
				if (htmlEvent.tagName.toUpperCase() !== 'A') {
					htmlEvent = htmlEvent.parentElement;
				}

				// get href if possible
				this.logService.debug('saveExternalclicks: found tag A');
				const aTarget: HTMLAnchorElement = <HTMLAnchorElement>htmlEvent;
				// if href is empty it could be a dialog opening. In that case we have href in attribute contenthref
				if (aTarget.href.length > 0) {
					clickTo = aTarget.href;
				} else if (aTarget.getAttribute('contenthref')?.length > 0) {
					clickTo = aTarget.getAttribute('contenthref');
				}

				if (aTarget.getAttribute('contexttypeid')?.length > 0) {
					contextTypeId = Number(aTarget.getAttribute('contexttypeid'));
				}

				if (aTarget.getAttribute('contextid')?.length > 0) {
					contextId = Number(aTarget.getAttribute('contextid'));
				}

				if (clickTo.length === 0) {
					tagError = true;
				}

				if (!tagError) {
					// internal lnks are managed elsewhere
					tagError = !this.isExternalUrl(
						clickTo,
						this.environmentService.clientDomain,
						this.environmentService.contentPath
					);
				}

				clickElement = {
					clickTo: clickTo,
					context: {
						contextID: contextId,
						contextTypeID: contextTypeId
					}
				};
				if (!tagError) {
					return clickElement;
				}
			}),
			map((clickElement) =>
				saveClick({
					clickTo: clickElement ? clickElement.clickTo : undefined,
					action: '',
					context: clickElement ? clickElement.context : undefined
				})
			)
		)
	);

	savePageViews = createEffect(() =>
		/*		this.router.events.pipe(
					filter((event) => event instanceof NavigationEnd),
			*/
		this.actions$.pipe(
			ofType(ROUTER_NAVIGATED),
			tap((action: RouterNavigationAction) =>
				this.logService.info('Effect: savePageviews ', action)
			),
			onlyClient(),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			onlyWhenSession(this.store),
			map((action: RouterNavigationAction) => action.payload.routerState.url),
			map((url: string) => saveClick({ clickTo: url }))

			/*
					map((event) => event as NavigationEnd),
					tap((event) => this.logService.info('Effect: click savePageviews: ', event)),
					filter((event) => !!event.urlAfterRedirects),
					map((event) => event.urlAfterRedirects),
					map((url: string) => saveClick({ clickTo: url }))
				)
			*/
		)
	);

	saveClickLog$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveClickLog),
			tap((action) => this.logService.info('Effect: saveClickLog ', action)),
			map((payload) => payload.clickLog),
			filter((clickLog) => !!clickLog),
			concatLatestFrom(() => [this.store.select(selectSessionID)]),
			tap((event) => this.logService.info('saveClickLog received event', event)),

			mergeMap(([clickLog, sessionID]) => {
				this.logService.debug('Effect: saveClickLog calling CreateClickLog ');
				clickLog.sessionID = sessionID;
				return this.clickService.CreateClickLog(clickLog).pipe(
					tap((response: ClickLogID) =>
						this.logService.infoDebug('Effect: saveClickLogSuccess ', response)
					),
					map(() => saveClickLogSuccess()),
					catchError((error: ErrorMessage) => of(saveClickLogFailure({ error })))
				);
			})
		)
	);

	//  save failure
	saveClickLogFailure$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveClickLogFailure),
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			tap((action) => this.logService.info('Effect: saveClickLogFailure ', action)),
			map(([{ error }, authLoginState]) => manageError(error, authLoginState))
		)
	);

	getClickParametersFromUrl(
		url: string,
		sessionID: number,
		templateAttributes: DottnetTemplate,
		action: string,
		rootDomain: string,
		contextTypeID: number,
		contextID: number
	): ClickDetail | ErrorMessage {
		let clickDetail: ClickDetail = <ClickDetail>{
			sessionID: 0,
			page: '',
			contextTypeID: 0,
			contextID: 0,
			contentTypeID: 0,
			contentID: 0,
			templateID: 0,
			permaLink: '',
			queryString: ''
		};

		const EXTERNALPAGE: string = 'External page';
		const WRONGPAGE: string = 'Wrong page';

		let errorMessage: ErrorMessage = new ErrorMessage();
		errorMessage.url = url;

		let containerId: string = '';
		let contentId: string = '';
		let isExternalLink: boolean = false;

		clickDetail.sessionID = sessionID;

		if (!url || typeof url !== 'string' || url.length === 0) {
			errorMessage.Code = ErrorType.ErrorUrlWrong;
			errorMessage.messageDN = 'Url not correct. Click detail not generated';
			return errorMessage;
		}

		if (url === '/') {
			clickDetail.page = '/';
			clickDetail.permaLink = '/';
			return clickDetail;
		}

		url = url.replaceAll("'", "''");

		// understand id it's an internal or external link
		if (url.startsWith('http')) {
			// remove host if necessary
			if (url.startsWith(rootDomain)) {
				// internal link. Remove host and go on
				url = url.slice(rootDomain.length);
			} else {
				//  external link manage it separately
				isExternalLink = true;
			}
		}

		// sometimes the first segment is 0 lenght because the link starts with //
		if (url.startsWith('//')) {
			url = url.substring(1);
		}

		// extract url segments and fragments
		// TODO: manage / url
		let cleanUrl: string = '';
		let queryString: string = '';
		let fragment: string = '';
		let tmp: string[] = url.split('?');
		cleanUrl = tmp[0];
		if (tmp.length > 1) {
			queryString = tmp[1];
			tmp = queryString.split('#');
			if (tmp.length > 1) {
				queryString = tmp[0];
				fragment = tmp[1];
			}
		} else {
			tmp = queryString.split('#');
			if (tmp.length > 1) {
				cleanUrl = tmp[0];
				fragment = tmp[1];
			}
		}

		// equal for all links
		clickDetail.queryString = queryString + fragment;

		if (isExternalLink) {
			//  permalink is everything before querystring
			clickDetail.page = EXTERNALPAGE;
			clickDetail.permaLink = cleanUrl;
			clickDetail.templateID = 14; // link
			clickDetail.contextTypeID = contextTypeID;
			clickDetail.contextID = contextID;
			return clickDetail;
		}

		const segments: string[] = cleanUrl.split('/');
		if (segments[0] === '') {
			segments.splice(0, 1);
		}

		// if there is a final / in path there will be an empty final array element
		if (segments[segments.length - 1] === '') {
			segments.splice(segments.length - 1, 1);
		}

		// is it a known template?
		const containerTemplateData: TemplateCt = templateAttributes[segments[0].toLowerCase()];

		const isWrongLink = containerTemplateData === undefined;

		if (isWrongLink) {
			//  permalink is everything before querystring
			clickDetail.page = WRONGPAGE;
			clickDetail.permaLink = cleanUrl;
			clickDetail.templateID = 14; // link
			return clickDetail;
		}

		if (segments.length > 8) {
			errorMessage.Code = ErrorType.ErrorUrlTooManySegments;
			errorMessage.messageDN = 'Url object segments over maximum';
			return errorMessage;
		}

		// action can be PrintPdf, SavePdf
		if (action.length > 0) {
			clickDetail.page = action;
		} else {
			clickDetail.page = segments[0].toLowerCase();
		}

		// here we know that url is well formed and first segment is a known template
		// now we want to know if it's a content page, and we need to check contentid, container and so on, or not
		if (!(containerTemplateData.isContent || containerTemplateData.isContainer)) {
			clickDetail.permaLink = '/' + segments.join('/');
			clickDetail.templateID = containerTemplateData.templateID;
			clickDetail.contentTypeID = containerTemplateData.contentTypeID;
			return clickDetail;
		}

		// here, first segment is container or content
		// if there are up until 3 segments, they are container, id, title
		// it here are 4 or more segments, we need to check two possible outcomes:
		// 1) container, id, title, but title has some / inside
		// 2) container, id, content, id, title

		// up to 3 segments
		if (segments.length <= 3) {
			clickDetail.permaLink = '/' + segments.join('/');
			clickDetail.templateID = containerTemplateData.templateID;
			clickDetail.contentTypeID = containerTemplateData.contentTypeID;
			if (containerTemplateData.isContainer) {
				clickDetail.contextTypeID = containerTemplateData.contentTypeID;
			}
			if (segments.length > 1) {
				contentId = segments[1];
				if (contentId.length > 0 && isNumber(contentId)) {
					clickDetail.contentID = Number(contentId);
					if (containerTemplateData.isContainer) {
						clickDetail.contextID = Number(contentId);
					}
				}
			}
			return clickDetail;
		}

		// 4 segments or more
		// check if we are in case 1) or 2) as deswcribed above
		const contentTemplateData: TemplateCt = templateAttributes[segments[2].toLowerCase()];

		containerId = segments[1];
		contentId = segments[3];

		if (contentTemplateData && contentTemplateData.isContent && isNumber(contentId)) {
			// case 2)
			// first segment has to be a container, ad only containers are allowed to show subcontents
			if (!containerTemplateData.isContainer || !isNumber(containerId)) {
				errorMessage.Code = ErrorType.ErrorUrlWrongContainer;
				errorMessage.messageDN = 'Container is not a proper container';
				return errorMessage;
			}

			clickDetail.permaLink = '/' + segments.join('/');

			clickDetail.contextID = Number(containerId);
			clickDetail.contextTypeID = containerTemplateData.contextTypeID;

			clickDetail.contentID = Number(contentId);
			clickDetail.contentTypeID = contentTemplateData.contentTypeID;

			clickDetail.templateID = contentTemplateData.templateID;

			return clickDetail;
		}

		// case 1).  segments or more, but single content

		clickDetail.permaLink = '/' + segments.join('/');

		clickDetail.templateID = containerTemplateData.templateID;

		clickDetail.contentTypeID = containerTemplateData.contentTypeID;
		contentId = segments[1];
		if (contentId.length > 0 && isNumber(contentId)) {
			clickDetail.contentID = Number(contentId);
		}

		// console.log('Click detail generated: ', clickDetail);
		return clickDetail;
	}

	isExternalUrl(url: string, rootDomain: string, contentPath: string): boolean {
		if (!url || typeof url !== 'string' || url.length === 0) {
			return false;
		}

		// understand id it's an internal or external link
		if (!url.startsWith('http')) {
			return false;
		}
		// remove host if necessary
		if (url.startsWith(rootDomain)) {
			// internal link. Remove host and go on
			return false;
		}
		if (url.startsWith(contentPath)) {
			// internal link. Remove host and go on
			// content link to a content file
			return false;
		}
		return true;
	}
}
