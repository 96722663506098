import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { selectAuthLoginState } from '../../core/auth/auth.selectors';
import { LogService } from '../../core/log/log.service';
import { onlyWhenAuthenticated, onlyWhenOnline } from '../../shared/util/operators';
import { ErrorMessage } from '../error/error-message.model';
import { manageError } from '../error/error.util';
import { loadProfession, loadProfessionFailure, loadProfessionSuccess } from './profession.actions';
import { ProfessionType } from './profession.model';
import { selectProfession } from './profession.selectors';
import { ProfessionService } from './profession.service';

@Injectable({
	providedIn: 'root'
})
export class ProfessionEffects {
	loadProfession$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadProfession),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			tap((action) => this.logService.info('Effect: loadProfession', action)),
			concatLatestFrom(() => this.store.select(selectProfession)),
			filter(([_, professiontypes]) => professiontypes.length === 0),
			switchMap(() =>
				this.professionService.loadProfession().pipe(
					tap((datiProfession: ProfessionType[]) =>
						this.logService.infoDebug('Effect: loadProfession data received --> ', datiProfession)
					),
					map((datiProfession: ProfessionType[]) => loadProfessionSuccess({ datiProfession })),
					catchError((error: ErrorMessage) => of(loadProfessionFailure({ error })))
				)
			)
		)
	);

	//  load failure
	loadProfessionFailure$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadProfessionFailure),
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			tap((action) => this.logService.info('Effect: loadProfessionFailure ', action)),
			map(([{ error }, authLoginState]) => manageError(error, authLoginState))
		)
	);

	constructor(
		private actions$: Actions,
		private professionService: ProfessionService,
		private logService: LogService,
		private store: Store
	) {}
}
